import {consume} from '@lit/context';
import {LitElement, html, nothing} from 'lit';
import {customElement, property, query} from 'lit/decorators.js';
import {VtApiError} from '../../api-client/error';
import {ApiClient} from '../../api-client/index';
import '../../components/code-editor';
import {CodeEditor} from '../../components/code-editor/index';
import '../../components/editor-console/editor-console';
import {EditorConsole} from '../../components/editor-console/editor-console';
import '../../components/retrohunt-navbar/retrohunt-navbar';
import '../../components/retrohunt-settings/retrohunt-settings';
import {apiClientContext} from '../../context/api/index';
import {CurrentUser, currentUserContext} from '../../context/auth/index';
import {manuallyInsertSnippet} from '../../context/editor/language/yara/providers/completion';
import {routerContext} from '../../context/router/index';
import {Router} from '../../context/router/utils';
import {
  AddSnippetToRulesetRequested,
  CodeDiagnosticsReported,
  CodeEditorChanged,
  RetrohuntSettingsChanged,
  SaveRulesetRequested,
  ShowToastRequested,
  UnsavedStateUpdated,
} from '../events';
import './../../components/side-bar/side-bar';
import {
  ExistingRetrohuntJobController,
  NewRetrohuntJobController,
  RetrohuntJobController,
} from './controllers';
import {Diagnostic} from '../../components/editor-console/diagnostics';
import '../../components/resizable-container/resizable-container';

const QUOTA_KEY = 'intelligence_retrohunt_jobs_monthly';

@customElement('retrohunt-view')
export class RetrohuntView extends LitElement {
  @consume({context: currentUserContext, subscribe: true})
  @property({attribute: false})
  public currentUser!: CurrentUser;

  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  @consume({context: routerContext})
  @property({attribute: false})
  public router!: Router;

  @property({type: String})
  public consoleTestInput = '';

  @property({type: String})
  public codeEditorValue = '';

  @query('editor-console')
  private editorConsole!: EditorConsole;

  @query('code-editor')
  private codeEditor!: CodeEditor;

  // These two properties below are intended to be used only for the controller construction.
  // Use the controller properties in the view logic.
  public readonly retrohuntJobId: string | undefined;

  private retrohuntJobController!: RetrohuntJobController;

  public createRenderRoot() {
    return this;
  }

  public connectedCallback(): void {
    super.connectedCallback();
    if (this.retrohuntJobId) {
      this.setController(
        ExistingRetrohuntJobController.new(
          this,
          this.apiClient,
          this.retrohuntJobId!
        )
      );
    } else {
      this.setController(NewRetrohuntJobController.new(this, this.apiClient));
    }

    this.addEventListener(
      AddSnippetToRulesetRequested.id,
      this.addCodeToRuleset
    );

    this.addEventListener(
      RetrohuntSettingsChanged.id,
      (e: RetrohuntSettingsChanged) => {
        this.retrohuntJobController.prepareAttributesUpdate(e.detail);
        this.dispatchEvent(
          new UnsavedStateUpdated(this.retrohuntJobController.hasUnsavedChanges)
        );
      }
    );

    this.addEventListener(CodeDiagnosticsReported.id, (e) =>
      this.setDiagnostics(e.detail)
    );

    this.addEventListener(SaveRulesetRequested.id, this.createRetrohuntJob);
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      // (ctrl | cmd) + s
      if (e.key == 's' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        this.createRetrohuntJob();
      }
    });
  }

  public reportDiagnostics(diagnostics: Diagnostic[]) {
    this.dispatchEvent(new CodeDiagnosticsReported(diagnostics));
  }

  public setDiagnostics(diagnostics: Diagnostic[]) {
    const rangedDiagnostics = this.codeEditor.setDiagnostics(diagnostics);
    if (!rangedDiagnostics) return;
    this.editorConsole.setDiagnostics(rangedDiagnostics);
  }

  public setController(controller: RetrohuntJobController) {
    this.retrohuntJobController = controller;
  }

  private consoleTestInputChangedHandler(e: CustomEvent<string>) {
    this.consoleTestInput = e.detail;
  }

  public render() {
    return html`<main class="vh-100 vstack">
      <nav
        class="navbar bg-body border-bottom container-fluid py-0 pe-3 hstack gap-4"
      >
        <retrohunt-navbar
          .retrohuntJobId="${this.retrohuntJobController.id}"
          .saving="${this.retrohuntJobController.isSaving}"
          .rules="${this.codeEditorValue}"
          class="flex-grow-1"
        ></retrohunt-navbar>
        <theme-switch></theme-switch>
        <user-menu .user=${this.currentUser}></user-menu>
      </nav>
      <div class="container-fluid flex-grow-1 d-flex bg-body-secondary">
        <div class="workspace w-100 row flex-grow-1 flex-nowrap">
          <side-bar
            class="col-auto row flex-nowrap"
            .readonly="${!!this.retrohuntJobController.id}"
            .rulesetKind="${'file'}"
            .consoleTestInput="${this.consoleTestInput}"
            .settings="${this.retrohuntJobController.settings}"
            .savingSettings="${this.retrohuntJobController.isSaving}"
          ></side-bar>
          <main class="col p-0 vstack">
            <!-- Editor -->
            <div class="flex-grow-1 position-relative">
              <code-editor
                .value="${this.retrohuntJobController.rules || ''}"
                .options="${{
                  readOnly: !!this.retrohuntJobController.id,
                }}"
                .kind="${'file'}"
                @code-editor-changed="${this.onCodeEditorValueChanged}"
              ></code-editor>
              ${this.retrohuntJobController.isLoadingRuleset
                ? html`<div
                    class="position-absolute top-0 left-0 w-100 h-100 hstack gap-3 align-items-center justify-content-center bg-body opacity-75"
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <h2 class="m-0">
                      Loading ruleset ${this.retrohuntJobController.id}
                    </h2>
                  </div>`
                : nothing}
            </div>

            <!-- Bottom toolbar -->
            <editor-console
              @console-test-input-changed="${this
                .consoleTestInputChangedHandler}"
              .codeEditorValue="${this.codeEditorValue}"
              .kind="${'file'}"
              .usedQuota="${this.currentUser.quotas?.[QUOTA_KEY].used}"
              .totalQuota="${this.currentUser.quotas?.[QUOTA_KEY].allowed}"
              .userEnterpriseGroup="${this.currentUser.mainGroupId!}"
            ></editor-console>
          </main>

          <resizable-container
            .edges="${{left: true}}"
            class="sidebar col-auto border-start"
          >
            <retrohunt-settings
              .readonly="${!!this.retrohuntJobController.id}"
              .settings="${this.retrohuntJobController.settings}"
              .userPrivileges="${this.currentUser.privileges}"
            ></retrohunt-settings>
          </resizable-container>
        </div>
      </div>
    </main>`;
  }

  private onCodeEditorValueChanged(e: CodeEditorChanged) {
    this.codeEditorValue = e.detail.value;
    this.retrohuntJobController.prepareAttributesUpdate({
      rules: this.codeEditorValue,
    });
    this.dispatchEvent(
      new UnsavedStateUpdated(this.retrohuntJobController.hasUnsavedChanges)
    );
  }

  private async createRetrohuntJob() {
    if (this.retrohuntJobController.id) {
      // Job already exists.
      return;
    }
    const unsavedChanges = this.retrohuntJobController.unsavedDelta;
    if (!Object.keys(unsavedChanges).length) {
      this.dispatchEvent(
        new ShowToastRequested({
          message: `Nothing to save`,
        })
      );
      return;
    }
    try {
      await this.retrohuntJobController.saveRuleset(unsavedChanges);
      this.dispatchEvent(new UnsavedStateUpdated(false));
      this.dispatchEvent(
        new ShowToastRequested({
          message: 'Retrohunt launched!',
          action: {
            label: 'Go',
            linkOrFn: 'https://www.virustotal.com/gui/hunting/retrohunt',
          },
        })
      );
    } catch (e: unknown) {
      if (e instanceof VtApiError && e.status === 400) return;
      this.dispatchEvent(
        new ShowToastRequested({
          message: VtApiError.getMessage(
            e,
            'There was an error saving the ruleset'
          ),
        })
      );
    }
  }

  /**
   * Appends code at the end scrolling to the new content and placing the caret position right
   * after the appended code
   * @param e
   */
  private addCodeToRuleset({
    detail: {snippet, dependencies},
  }: AddSnippetToRulesetRequested) {
    if (this.retrohuntJobController.id) return;
    const monacoEditor = this.codeEditor.editor;
    if (monacoEditor) {
      manuallyInsertSnippet(monacoEditor, snippet, dependencies);
    }
  }

  public static existingRulesetTemplate(jobId: string | undefined) {
    return html`<retrohunt-view .retrohuntJobId="${jobId}"></retrohunt-view>`;
  }

  public static newRulesetTemplate() {
    return html`<retrohunt-view></retrohunt-view>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'retrohunt-view': RetrohuntView;
  }
}
