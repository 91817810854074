import {consume} from '@lit/context';
import {LitElement, TemplateResult, html, nothing} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {debounce} from 'lodash';
import {ApiClient} from '../../api-client/index';
import {RulesetTargetKind} from '../../api-client/rulesets/types';
import {apiClientContext} from '../../context/api/index';
import {
  checkCircleFilledIcon,
  chevronDownIcon,
  chevronUpIcon,
  exclamationCircleFilledIcon,
  exclamationTriangleFilledIcon,
  infoCircleIcon,
  spinnerQuarterIcon,
  targetEyeIcon,
  xmarkCircleFilledIcon,
} from '../../icons';
import {capitalize, getEscapedSnippet, initializePopovers} from '../../utils';
import {CodeDiagnosticsReported} from '../../view/events';
import {Diagnostic, DiagnosticSeverity} from './diagnostics';
import {FailedTestData, MatchingTestData, Test, TestSuite} from './test-suite';
import '../resizable-container/resizable-container';

const KIND_TO_PLURAL_HUMAN_ID = {
  file: 'file hashes',
  url: 'URLs',
  domain: 'domains',
  ip_address: 'IP addresses',
} as const;

const TABS = ['Test', 'Test results', 'Problems'] as const;

const QUOTA_THRESHOLD = 0.85;

const TEST_ENTITIES_LIMIT = 50;

const NAV_HEIGHT_PX = 49;

@customElement('editor-console')
export class EditorConsole extends LitElement {
  @consume({context: apiClientContext})
  @property({attribute: false})
  public apiClient!: ApiClient;

  @property({type: String})
  public codeEditorValue = '';

  @property({type: String})
  public kind: RulesetTargetKind = 'file';

  @property({type: Boolean})
  public collapsed = false;

  @property({type: Number})
  public usedQuota?: number;

  @property({type: Number})
  public totalQuota?: number;

  @property({type: Number})
  public quotaConsumptionAlertThreshold = QUOTA_THRESHOLD;

  @property({type: String})
  public userEnterpriseGroup?: string;

  @state()
  private diagnostics: Diagnostic[] = [];

  @state()
  private currentSection: (typeof TABS)[number] = 'Test';

  private inputValue = '';

  private testSuite?: TestSuite;

  public createRenderRoot() {
    return this;
  }

  protected updated() {
    initializePopovers(this);
  }

  public reportDiagnostics(diagnostics: Diagnostic[]) {
    this.dispatchEvent(new CodeDiagnosticsReported(diagnostics));
  }

  public setDiagnostics(diagnostics: Diagnostic[]) {
    this.diagnostics = diagnostics;
    if (this.hasErrors) {
      this.setCurrentSection('Problems');
    }
  }

  private get warnings() {
    return this.diagnostics.filter(
      ({severity}) => severity === DiagnosticSeverity.Warning
    );
  }

  private get infos() {
    return this.diagnostics.filter(
      ({severity}) => severity === DiagnosticSeverity.Info
    );
  }

  private get hints() {
    return this.diagnostics.filter(
      ({severity}) => severity === DiagnosticSeverity.Hint
    );
  }

  private get hasErrors() {
    return this.diagnostics.some(
      ({severity}) => severity === DiagnosticSeverity.Error
    );
  }

  private get errors() {
    return this.diagnostics.filter(
      ({severity}) => severity === DiagnosticSeverity.Error
    );
  }

  private runTests(e: SubmitEvent) {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target as HTMLFormElement;
    if (!form.checkValidity()) {
      form.classList.add('was-validated');
      return;
    }
    const formData = new FormData(form);
    const items = new Set(
      (formData.get('items')! as string)
        .trim()
        .split(/\s/)
        .filter((l) => l)
    );
    if (items.size > TEST_ENTITIES_LIMIT) {
      (form.elements.namedItem('items') as HTMLTextAreaElement).classList.add(
        'is-invalid'
      );
      return;
    }
    const ruleset = formData.get('ruleset') as string;
    this.testSuite = new TestSuite(
      this,
      this.apiClient,
      [...items],
      this.kind,
      ruleset
    );
    this.setCurrentSection('Test results');
  }

  private consumptionPercent(used: number, total: number) {
    if (!used || used > total) return 0;
    if (used === total) return 100;
    return Math.round((used / total) * 100);
  }

  private get problems() {
    const used = this.usedQuota || 0;
    const total = this.totalQuota || 0;
    const percent = this.consumptionPercent(used, total);

    return [
      ...this.errors.map(
        (error) => html`<span class="hstack gap-2"
          ><span class="hstack text-danger">${exclamationCircleFilledIcon}</span
          >${error.message}${'range' in error
            ? html`<span class="text-body-tertiary"
                >[Ln ${error.range.startLineNumber}, Col
                ${error.range.startColumn}]</span
              >`
            : nothing}</span
        >`
      ),
      ...this.warnings.map(
        (warning) => html`<span class="hstack gap-2"
          ><span class="hstack text-warning"
            >${exclamationTriangleFilledIcon}</span
          >${warning.message}${'range' in warning
            ? html`<span class="text-body-tertiary"
                >[Ln ${warning.range.startLineNumber}, Col
                ${warning.range.startColumn}]</span
              >`
            : nothing}</span
        >`
      ),
      ...this.hints.map(
        (hint) => html`<span class="hstack gap-2"
          ><span class="hstack text-primary"
            >${exclamationTriangleFilledIcon}</span
          >${hint.message}${'range' in hint
            ? html`<span class="text-body-tertiary"
                >[Ln ${hint.range.startLineNumber}, Col
                ${hint.range.startColumn}]</span
              >`
            : nothing}</span
        >`
      ),
      ...this.infos.map(
        (infos) => html`<span class="hstack gap-2"
          ><span class="hstack text-info">${infoCircleIcon}</span
          >${infos.message}${'range' in infos
            ? html`<span class="text-body-tertiary"
                >[Ln ${infos.range.startLineNumber}, Col
                ${infos.range.startColumn}]</span
              >`
            : nothing}</span
        >`
      ),
      percent >= this.quotaConsumptionAlertThreshold * 100
        ? html`<span class="hstack gap-2">
            ${percent == 100
              ? html`<span class="hstack text-danger"
                  >${exclamationCircleFilledIcon}</span
                >`
              : html`<span class="hstack text-warning"
                  >${exclamationTriangleFilledIcon}</span
                >`}
            ${percent == 100
              ? html`
                  You have exceeded your VT HUNTING Livehunt quota limit, no new
                  rules can be stored.
                `
              : html`
                  You are getting close to your VT HUNTING Livehunt quota limits
                  [${used} of ${total} rules].
                `}
            <a
              class="link-primary"
              href="https://www.virustotal.com/gui/group/${this
                .userEnterpriseGroup}/intelligence"
            >
              Check your consumption
            </a>
            or
            <a
              class="link-primary"
              href="https://www.virustotal.com/gui/contact-us/premium-services"
            >
              contact us
            </a>
          </span>`
        : null,
    ].filter((template): template is TemplateResult<any> => template !== null);
  }

  public render() {
    return html`
      <style>
        .nav-tabs {
          --bs-nav-tabs-link-active-bg: transparent;
          height: ${NAV_HEIGHT_PX}px;
        }

        textarea[name='items'] {
          resize: none;
        }

        .tab-pane {
          overflow: auto;
        }

        .tab-content {
          height: calc(100% - ${NAV_HEIGHT_PX}px);
        }

        details > summary {
          list-style-type: none;
        }

        details > summary::-webkit-details-marker {
          display: none;
        }

        .spinner {
          animation: rotate-spinner 1s linear infinite;
        }

        .collapsed {
          max-height: ${NAV_HEIGHT_PX}px;
        }

        @keyframes rotate-spinner {
          to {
            transform: rotate(360deg);
          }
        }

        .snippet-popover {
          --bs-popover-max-width: auto;
          .snippet-match {
            font-weight: bold;
          }
        }
      </style>
      <form novalidate @submit="${this.runTests}">
        <input name="ruleset" type="hidden" value="${this.codeEditorValue}" />
        <resizable-container
          .edges="${{
            top: true,
          }}"
          .minHeightPx="${NAV_HEIGHT_PX + 100}"
          style="height: ${NAV_HEIGHT_PX + 200}px"
          class="border-top vstack ${classMap({
            collapsed: this.collapsed,
          })}"
        >
          <div class="hstack">
            <ul
              class="nav nav-tabs border-top-0 border-bottom-0"
              role="tablist"
            >
              ${TABS.map(
                (tab) => html`<li class="nav-item" role="presentation">
                  <button
                    class="nav-link hstack gap-2 ${classMap({
                      active: this.currentSection === tab,
                      disabled: tab === 'Test results' && !this.testSuite,
                    })}"
                    aria-selected="${this.currentSection === tab}"
                    role="tab"
                    type="button"
                    data-tab="${tab}"
                    @click="${() => {
                      this.setCurrentSection(tab);
                    }}"
                  >
                    ${tab} ${this.renderTabBadge(tab)}
                  </button>
                </li>`
              )}
            </ul>
            <div class="ms-auto me-4 hstack gap-4">
              <button
                id="runTestBtn"
                class="btn btn-outline-primary btn-sm"
                type="submit"
              >
                Run test
              </button>
              <a
                class="hstack fs-3"
                role="button"
                @click="${() => {
                  this.collapsed = !this.collapsed;
                }}"
                >${this.collapsed ? chevronUpIcon : chevronDownIcon}</a
              >
            </div>
          </div>
          ${this.renderConsoleTabs()}
        </resizable-container>
      </form>
    `;
  }

  private renderTabBadge(tab: (typeof TABS)[number]) {
    switch (tab) {
      case 'Problems':
        return this.problems.length
          ? html`<span
              class="badge bg-body-tertiary text-body-tertiary rounded-pill"
              >${this.problems.length}</span
            >`
          : nothing;
      case 'Test results':
        return this.testSuite?.completedTests.length
          ? html`<span
              class="badge bg-body-tertiary text-body-tertiary rounded-pill"
              >${this.testSuite.passingTests.length} /
              ${this.testSuite.completedTests.length}</span
            >`
          : nothing;
      default:
        return nothing;
    }
  }

  private renderPendingTestResult(test: Test<any>) {
    return html`<span class="hstack gap-2 font-monospace">
      <span class="spinner hstack text-warning">
        ${spinnerQuarterIcon}
        <span class="visually-hidden">Loading...</span>
      </span>
      ${test.item}
    </span>`;
  }

  private renderFailedTestResult(test: Test<FailedTestData>) {
    return html`<details open>
      <summary class="hstack gap-2 font-monospace">
        <span class="d-flex text-danger" role="status"
          ><span class="visually-hidden">The test failed.</span
          >${xmarkCircleFilledIcon}</span
        >${test.item}
      </summary>
      <ul class="list-group pt-2 vstack gap-3">
        <li class="list-group-item bg-transparent border-0 py-0 text-danger">
          ${test.test.value?.error}
        </li>
      </ul>
    </details>`;
  }

  private renderMatchingTestResult(test: Test<MatchingTestData>) {
    return html`<details open>
      <summary class="hstack gap-2 font-monospace">
        <span class="d-flex text-success" role="status"
          ><span class="visually-hidden">The item matched the ruleset</span
          >${checkCircleFilledIcon}</span
        >${test.item}
      </summary>
      <ul class="list-group pt-2 vstack gap-3">
        ${test.test.value?.matches.map(
          (match) =>
            html`<li
              class="list-group-item bg-transparent border-0 py-0 text-primary"
            >
              <span class="d-inline-flex gap-2 align-items-center">
                <a
                  role="button"
                  class="d-flex btn btn-link p-0 ${classMap({
                    'text-primary': !!match.snippet,
                    'disabled': !match.snippet,
                    'text-muted': !match.snippet,
                  })}"
                  data-bs-container="body"
                  data-bs-toggle="popover"
                  data-bs-placement="top"
                  data-bs-title="Match context"
                  data-bs-content="${`<pre class="m-0">${
                    getEscapedSnippet(match.snippet) || ''
                  }</pre>`}"
                  data-bs-html="true"
                  data-bs-custom-class="snippet-popover"
                >
                  ${targetEyeIcon}
                </a>
                <span
                  >${match.ruleName}${match.matchSource
                    ? ` (matched in: ${match.matchSource
                        .toLowerCase()
                        .replaceAll('_', ' ')})`
                    : nothing}</span
                >
              </span>
            </li>`
        )}
      </ul>
    </details>`;
  }

  private handleInput(event: InputEvent) {
    // Update the inputValue property with the new value
    this.inputValue = (event.target as HTMLTextAreaElement).value;

    // Call the debouncedLaunchEvent function after the text area value has changed
    this.debouncedLaunchEvent();
  }

  private debouncedLaunchEvent = debounce(() => {
    this.dispatchEvent(
      new CustomEvent('console-test-input-changed', {detail: this.inputValue})
    );

    // Perform the launch event action here
  }, 500); // Adjust the debounce delay (in milliseconds) to fit your needs

  private renderConsoleTabs() {
    const kind =
      this.kind === 'file' ? 'files' : KIND_TO_PLURAL_HUMAN_ID[this.kind];
    const kindIds = KIND_TO_PLURAL_HUMAN_ID[this.kind];
    const capitalizedKind = capitalize(kindIds);
    return html`<div class="tab-content vstack">
      <div
        class="tab-pane h-100 bg-transparent border-0 p-3 border-top ${'Test' ===
          this.currentSection && !this.collapsed
          ? 'active'
          : ''}"
        role="tabpanel"
      >
        <div class="vstack h-100 form-group has-validation">
          <textarea
            class="w-100 h-100 form-control bg-transparent"
            name="items"
            @input=${this.handleInput}
            placeholder="${`Test your rules against ${kind} that are known to VirusTotal. Paste a list of ${kindIds} here and click on "Run test". ${
              this.kind === 'file' ? 'MD5, SHA1, SHA256 accepted. ' : ''
            }${capitalizedKind} should be separated by spaces.`}"
            required
            aria-required="true"
            spellcheck="false"
          ></textarea>
          <div class="invalid-feedback">
            Please paste the up to ${TEST_ENTITIES_LIMIT} elements you want to
            test this ruleset against before running the test.
          </div>
        </div>
      </div>
      <div
        class="tab-pane h-100 border-top ${'Test results' ===
          this.currentSection && !this.collapsed
          ? 'active'
          : ''}"
        role="tabpanel"
      >
        ${!this.testSuite
          ? html`<div class="p-3"><span>No tests have been run.</span></div>`
          : html`<ul class="list-group p-3 vstack gap-2">
              ${this.testSuite.tests.map(
                (test) =>
                  html`<li class="list-group-item bg-transparent border-0 p-0">
                    ${TestSuite.isTestRunning(test)
                      ? this.renderPendingTestResult(test)
                      : TestSuite.isFailedTest(test)
                      ? this.renderFailedTestResult(test)
                      : this.renderMatchingTestResult(test)}
                  </li>`
              )}
            </ul>`}
      </div>
      <div
        class="tab-pane h-100 border-top ${'Problems' === this.currentSection &&
        !this.collapsed
          ? 'active'
          : ''}"
        role="tabpanel"
      >
        ${this.problems.length
          ? html`<ul class="list-group p-3 vstack gap-2">
              ${this.problems.map(
                (problem) =>
                  html`<li class="list-group-item bg-transparent border-0 p-0">
                    ${problem}
                  </li>`
              )}
            </ul>`
          : html`<div class="p-3">No problems were found.</div>`}
      </div>
    </div>`;
  }

  private setCurrentSection(section: (typeof TABS)[number]) {
    this.currentSection = section;
    this.collapsed = false;
  }
}
