import {Task, TaskStatus} from '@lit/task';
import {ReactiveControllerHost} from 'lit';
import {VtApiError} from '../../api-client/error';
import {ApiClient} from '../../api-client/index';
import {SettingsAttributes} from '../../api-client/retrohunt-jobs/types';
import {APIObjectResponse, TypeToAttributes} from '../../api-client/service';
import {Settings} from '../../components/retrohunt-settings/retrohunt-settings';
import {Router} from '../../context/router/utils';
import {getRetrohuntJob} from '../../domain/retrohunt-jobs';
import {ShowToastRequested} from '../events';
import {
  Diagnostic,
  fromRawErrors,
  fromRawWarnings,
  fromYaraXDiagnostics,
} from '../../components/editor-console/diagnostics';
import {getDefaultRules} from '../../context/defaults';

export interface RetrohuntJobController {
  readonly id: string | undefined;
  readonly creationDate: number | undefined;
  readonly settings: Settings | undefined;
  readonly rules: string;
  readonly unsavedDelta: Partial<SettingsAttributes>;
  readonly isSaving: boolean;
  readonly isLoadingRuleset: boolean;
  readonly hasUnsavedChanges: boolean;
  saveRuleset(attr: Partial<SettingsAttributes>): Promise<void>;
  prepareAttributesUpdate(changes: Partial<SettingsAttributes>): void;
}

export type RetrohuntJobControllerConsumer = ReactiveControllerHost &
  EventTarget & {
    router: Router;
    setController: (controller: RetrohuntJobController) => void;
    reportDiagnostics: (diagnostics: Diagnostic[]) => void;
  };

export class NewRetrohuntJobController implements RetrohuntJobController {
  public readonly id = undefined;
  public readonly creationDate = undefined;
  public readonly isLoadingRuleset = false;
  private unsavedSettingsDelta: Partial<SettingsAttributes> = {};

  private readonly saveRulesetTask = new Task(
    this.host,
    ([attributes]: [Partial<SettingsAttributes>]) =>
      this.apiClient.retrohuntJobs.create(attributes).catch((e: Error) => e)
  );

  private constructor(
    private readonly host: RetrohuntJobControllerConsumer,
    private readonly apiClient: ApiClient
  ) {}

  public static new(
    host: RetrohuntJobControllerConsumer,
    apiClient: ApiClient
  ) {
    return new NewRetrohuntJobController(host, apiClient);
  }

  public get isSaving() {
    return this.saveRulesetTask.status === TaskStatus.PENDING;
  }

  private readonly defaultSettings = {
    corpus: 'main' as const,
    notification_email: '',
    time_range: undefined,
    rules: getDefaultRules(),
  } as const;

  public get settings(): Settings {
    return this.defaultSettings;
  }

  public get rules() {
    return this.defaultSettings.rules;
  }

  public get unsavedDelta() {
    return {
      ...this.settings,
      ...this.unsavedSettingsDelta,
    };
  }

  public get hasUnsavedChanges() {
    return Object.keys(this.unsavedSettingsDelta).length > 0;
  }

  public async saveRuleset(attributes: Partial<SettingsAttributes>) {
    await this.saveRulesetTask.run([attributes]);
    const res = this.saveRulesetTask.value!;
    this.host.reportDiagnostics([]);
    if (res instanceof Error) {
      if (res instanceof VtApiError && res.status === 400) {
        if (res.meta?.errors) {
          this.host.reportDiagnostics(
            fromYaraXDiagnostics([...res.meta.errors, ...res.meta.warnings])
          );
        } else {
          this.host.reportDiagnostics(fromRawErrors(res.message));
        }
      }
      throw res;
    } else {
      const warnings = !res.meta?.warnings
        ? []
        : 'string' === typeof res.meta.warnings
        ? fromRawWarnings(res.meta?.warnings)
        : fromYaraXDiagnostics(res.meta.warnings);
      this.host.reportDiagnostics(warnings);
      this.host.setController(
        ExistingRetrohuntJobController.fromResolvedTask(
          this.host,
          this.apiClient,
          res.data.id,
          {
            value: res,
            status: TaskStatus.COMPLETE,
          }
        )
      );
      this.host.router.updateUrl(`/retrohunt/${res.data.id}`);
      this.host.requestUpdate();
    }
  }

  public prepareAttributesUpdate(changes: Partial<SettingsAttributes>) {
    const unsavedSettings: Partial<SettingsAttributes> = {
      ...this.unsavedSettingsDelta,
      ...changes,
    };
    this.unsavedSettingsDelta = this.computeUnsavedAttributes(unsavedSettings);
  }

  private computeUnsavedAttributes = (current: Partial<SettingsAttributes>) => {
    return (
      Object.keys(this.defaultSettings) as (keyof SettingsAttributes)[]
    ).reduce((attrs, attrKey) => {
      if (!(attrKey in current)) return attrs;
      if (this.defaultSettings[attrKey] === current[attrKey]) return attrs;
      if (
        attrKey === 'time_range' &&
        JSON.stringify(this.defaultSettings.time_range) ===
          JSON.stringify(current.time_range)
      ) {
        return attrs;
      }
      return {
        ...attrs,
        [attrKey]: current[attrKey],
      };
    }, {} as Partial<SettingsAttributes>);
  };
}

interface RetrohuntJobTask {
  value?: APIObjectResponse<
    'retrohunt_job',
    never,
    keyof TypeToAttributes['retrohunt_job']
  >;
  status: TaskStatus;
}

const errorHandledGetRetrohuntJob = (
  host: EventTarget & {router: Router},
  api: ApiClient,
  id: string
) =>
  getRetrohuntJob(api, id).catch((e: unknown) => {
    host.dispatchEvent(
      new ShowToastRequested({
        message: VtApiError.getMessage(
          e,
          `There was an error loading the ruleset`
        ),
      })
    );
    host.router.redirect(`/retrohunt/new`);
    throw e;
  }) as ReturnType<typeof getRetrohuntJob>;

export class ExistingRetrohuntJobController implements RetrohuntJobController {
  private readonly retrohuntJob: RetrohuntJobTask;
  public unsavedDelta: Partial<SettingsAttributes> = {};

  public get isSaving() {
    return false;
  }

  public get isLoadingRuleset() {
    return this.retrohuntJob.status === TaskStatus.PENDING;
  }

  private get attributes() {
    return this.retrohuntJob.value?.data.attributes;
  }

  public get id() {
    return this.retrohuntJobId;
  }

  public get creationDate() {
    return this.attributes?.creation_date;
  }

  public get settings(): Settings | undefined {
    if (!this.attributes) return;
    const {corpus, notification_email, time_range} = this.attributes;
    return {corpus, notification_email, time_range};
  }

  public get rules(): string {
    return this.attributes?.rules || '';
  }

  public get hasUnsavedChanges() {
    return Object.keys(this.unsavedDelta).length > 0;
  }

  private constructor(
    private readonly host: RetrohuntJobControllerConsumer,
    private readonly apiClient: ApiClient,
    private readonly retrohuntJobId: string,
    retrohuntJobTask?: RetrohuntJobTask
  ) {
    this.retrohuntJob =
      retrohuntJobTask ??
      new Task(
        this.host,
        ([api, id]: [ApiClient, string]) =>
          errorHandledGetRetrohuntJob(this.host, api, id),
        () => [this.apiClient, this.retrohuntJobId] as [ApiClient, string]
      );
  }

  public static new(
    host: RetrohuntJobControllerConsumer,
    apiClient: ApiClient,
    rulesetId: string
  ) {
    return new ExistingRetrohuntJobController(host, apiClient, rulesetId);
  }

  public static fromResolvedTask(
    host: RetrohuntJobControllerConsumer,
    apiClient: ApiClient,
    rulesetId: string,
    rulesetTask: RetrohuntJobTask
  ) {
    return new ExistingRetrohuntJobController(
      host,
      apiClient,
      rulesetId,
      rulesetTask
    );
  }

  public async saveRuleset() {
    throw new Error('Retrohunt jobs cannot be updated');
  }

  public prepareAttributesUpdate() {}
}
