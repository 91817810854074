export enum DiagnosticSeverity {
  Error,
  Warning,
  Hint,
  Info,
}

export interface BaseDiagnostic {
  message: string;
  severity: DiagnosticSeverity;
}

export type RangedDiagnostic = BaseDiagnostic & {
  range: {
    readonly startLineNumber: number;
    readonly startColumn: number;
    readonly endLineNumber: number;
    readonly endColumn: number;
  };
};

export type Diagnostic =
  | RangedDiagnostic
  | (BaseDiagnostic &
      (
        | {line?: number}
        | {
            span: {
              start: number;
              end: number;
            };
          }
      ));

enum YaraXDiagnosticLevel {
  error = 'error',
  warning = 'warning',
  info = 'info',
  note = 'note',
  help = 'help',
}

interface YaraXDiagnosticLabel {
  level: YaraXDiagnosticLevel;
  span: {
    start: number;
    end: number;
  };
  text: string;
}

export interface YaraXDiagnostic {
  code: string;
  title: string;
  labels: YaraXDiagnosticLabel[];
  footers?: {text: string; level: YaraXDiagnosticLevel}[];
}

export const fromRawWarnings = (rawWarnings: string): Diagnostic[] => {
  return fromRawDiagnostic(rawWarnings, DiagnosticSeverity.Warning);
};

export const fromRawErrors = (rawErrors: string): Diagnostic[] => {
  return fromRawDiagnostic(rawErrors, DiagnosticSeverity.Error);
};

const yaraXLevelToDiagnosticSeverity = (
  level: YaraXDiagnosticLevel
): DiagnosticSeverity => {
  switch (level) {
    case YaraXDiagnosticLevel.error:
      return DiagnosticSeverity.Error;
    case YaraXDiagnosticLevel.warning:
      return DiagnosticSeverity.Warning;
    case YaraXDiagnosticLevel.info:
      return DiagnosticSeverity.Info;
    case YaraXDiagnosticLevel.note:
    case YaraXDiagnosticLevel.help:
      return DiagnosticSeverity.Hint;
  }
};

export const fromYaraXDiagnostics = (
  diagnostics: YaraXDiagnostic[]
): Diagnostic[] => {
  return diagnostics.flatMap((yaraXdiagnostic) =>
    yaraXdiagnostic.labels.map((label) => ({
      message: `${yaraXdiagnostic.title}: ${label.text}.${
        yaraXdiagnostic.footers
          ? `

${yaraXdiagnostic.footers
  .map((footer) => `${footer.level}: ${footer.text}`)
  .join('\n')}`
          : ''
      }`,
      severity: yaraXLevelToDiagnosticSeverity(label.level),
      span: label.span,
    }))
  );
};

const CODE_PROBLEM_REGEXP = /^Line (?<line>\d+):\s*(?<message>.+)$/;

const fromRawDiagnostic = (
  rawDiagnostics: string,
  severity: DiagnosticSeverity
): Diagnostic[] => {
  if (!rawDiagnostics) return [];
  return rawDiagnostics.split('\n').map((rawDiagnostic) => {
    rawDiagnostic = rawDiagnostic.trim();
    const codeProblemMatch = rawDiagnostic.match(CODE_PROBLEM_REGEXP) as {
      groups: {
        line: string;
        message: string;
      };
    } | null;
    if (!codeProblemMatch) {
      return {
        message: rawDiagnostic,
        severity,
      };
    }
    return {
      message: codeProblemMatch.groups.message,
      line: parseInt(codeProblemMatch.groups.line),
      severity,
    };
  });
};
